<template>

  <b-overlay
    :show="$apollo.loading"
    spinner-variant="primary"
  >
    <b-card>
      <div class="d-flex align-items-center">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          @click="$router.back()"
        >
          <feather-icon
            class="mr-25"
            icon="ChevronLeftIcon"
          />
        </b-button>
        <h3 class="mb-0 ml-2 d-flex justify-content-between align-items-center">
          <!--   @TODO: Replace static Image with S3. -->
          <img
            :style="{width: '50px', height: 'auto'}"
            alt="Company Logo"
            class="mr-25"
            src="https://static-00.iconduck.com/assets.00/google-icon-512x512-yk2xx8br.png"
          >
          <span>{{ student.full_name }}</span>
        </h3>
      </div>
      <hr>
      <b-table-simple
        bordered
        responsive
      >
        <b-tr>
          <b-td>Phone</b-td>
          <b-td>{{ student.phone_number }}</b-td>
        </b-tr>
        <b-tr>
          <b-td>Email</b-td>
          <b-td>{{ student.email }}</b-td>
        </b-tr>
        <b-tr>
          <b-td>Website</b-td>
          <b-td>
            <b-link
              :href="prependHttps(student.website)"
              target="_blank"
            >{{ student.website }}
            </b-link>
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>LinkedIn Profile</b-td>
          <b-td>
            <b-link
              :href="prependHttps(student.linkedin_profile)"
              target="_blank"
            >{{ student.linkedin_profile }}
            </b-link>
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>Facebook Profile</b-td>
          <b-td>
            <b-link
              :href="prependHttps(student.facebook_profile)"
              target="_blank"
            >{{ student.facebook_profile }}
            </b-link>
          </b-td>
        </b-tr>
      </b-table-simple>
    </b-card>
  </b-overlay>
</template>
<script>

import {
  BCard, BLink, BTableSimple, BTd, BTr,
} from 'bootstrap-vue'

import gql from 'graphql-tag'

export default {
  components: {
    BLink,
    BCard,
    BTableSimple,
    BTr,
    BTd,
  },
  data() {
    return {
      student: {},
    }
  },
  apollo: {
    student: {
      query() {
        return gql`
           query about_student {
           users_customuser(where: {users_associationtables: {id: {_eq: ${this.$route.params.id} }}}) {
             full_name
             phone_number
             email
             website
             linkedin_profile
             facebook_profile
          }
        }`
      },
      update: data => data.users_customuser[0],
    },
  },
}
</script>
